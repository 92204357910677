import store from "@/store";
import AccountModule from "@/store/modules/Account";
import Routes from "@boms/pages/Routes.vue";
import NProgress from "nprogress";
import "nprogress/nprogress.css"; // progress bar style
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

NProgress.configure({
  showSpinner: false,
  easing: "ease",
  speed: 500,
  trickleSpeed: 800,
});

if (
  store.state.LocalStorage &&
  "token" in store.state.LocalStorage &&
  !!store.state.LocalStorage.token
) {
  AccountModule.loginSuccess(store.state.LocalStorage.token);
  const isAuthenticated = AccountModule.getUserInfo?.isAuthenticated;
  if (isAuthenticated) {
    AccountModule.getAccountProfile();
  }
}

const view = (name: string) => () =>
  import(/* webpackChunkName: "[request]" */ `@boms/pages/${name}.vue`);

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    meta: {
      withAuth: true,
      layout: "default",
    },
    component: Routes,
    children: [
      {
        path: "",
        component: Routes,
        icon: "home",
        children: [
          {
            path: "",
            name: "Tenant Management",
            component: view("TenantManagement/index"),
          },
          {
            path: "create",
            name: "Create Tenant",
            component: view("TenantManagement/CRUD"),
          },
          {
            path: "tenant/:TenantId/edit",
            name: "Edit Tenant",
            component: view("TenantManagement/CRUD"),
          },
        ],
      },
      {
        path: "pending-request",
        name: "Pending Request",
        component: view("PendingRequest"),
        icon: "carbon_request-quote",
      },
      {
        path: "verify-request",
        component: Routes,
        icon: "bi_list-check",
        children: [
          {
            path: "",
            name: "Verify Request",
            component: view("VerifyRequest/index"),
          },
          {
            path: ":id",
            name: "Verify Request Detail",
            component: view("VerifyRequest/[id]"),
          },
        ],
      },
      {
        path: "profile",
        name: "Profile",
        component: view("Profile"),
        icon: "profile",
      },
    ],
  },
  {
    path: "/tenant",
    redirect: "/",
  },
  {
    path: "/tenant/:TenantId",
    name: "Tenant Detail Management",
    meta: {
      withAuth: true,
      layout: "default",
    },
    component: Routes,
    children: [
      {
        path: "",
        name: "Tenant Detail Management",
        component: view("TenantDetailManagement/[TenantId]"),
        icon: "home",
      },
      {
        path: "facility-management",
        component: Routes,
        icon: "dashboard",
        children: [
          {
            path: "",
            name: "Facility Management",
            component: view("TenantDetailManagement/FacilityManagement/index"),
          },
          {
            path: "create",
            name: "Create New Facility",
            component: view(
              "TenantDetailManagement/FacilityManagement/CRUDFacility"
            ),
          },
          {
            path: ":FacilityId/edit",
            name: "Edit Facility",
            component: view(
              "TenantDetailManagement/FacilityManagement/CRUDFacility"
            ),
          },
          {
            path: ":FacilityId",
            name: "Facility Detail Management",
            component: view(
              "TenantDetailManagement/FacilityManagement/[FacilityId]"
            ),
          },
          {
            path: "connect",
            name: "Connect Third Party",
            component: view(
              "TenantDetailManagement/FacilityManagement/ConnectThirdParty"
            ),
          },
        ],
      },
      {
        path: "user-management",
        component: Routes,
        icon: "user_management",
        children: [
          {
            path: "",
            name: "User Management",
            component: view("TenantDetailManagement/UserManagement/index"),
          },
          {
            path: "create-user",
            name: "Create New User",
            component: view("TenantDetailManagement/UserManagement/CRUDUser"),
          },
          {
            path: ":UserId/edit",
            name: "Edit User",
            component: view(
              "TenantDetailManagement/UserManagement/DetailEditUser"
            ),
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    meta: { layout: "blank" },
    component: view("Login"),
  },
  {
    path: "/:404(.*)*",
    name: "NotFound",
    meta: { layout: "blank" },
    component: view("NotFound"),
  },
] as any;

const router = createRouter({
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  history: createWebHashHistory(process.env.BASE_URL),
  scrollBehavior(_to, _from, savedPosition) {
    return savedPosition || { top: 0 };
  },
  routes,
});

router.beforeEach(async (to) => {
  NProgress.start();

  const isAuthenticated = AccountModule.getUserInfo?.isAuthenticated;

  if ("withAuth" in to.meta && to.meta.withAuth && !isAuthenticated) {
    return { name: "Login", query: { redirect: to.fullPath } };
  }
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
